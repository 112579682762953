<template>
  <div class="Ukij" style="background-size: contain; height: 100vh;" oncontextmenu="return false"
    ondragstart="return false">
    <div style='margin: 10px 0;'>
      <el-tag style="margin: 5px;" type="primary">تېلۋىزور: 1</el-tag>
      <el-tag style="margin: 5px;" type="success">ئىككى يۈز يۈەن نەق پۇل: 0</el-tag>
      <el-tag style="margin: 5px;" type="info">كىر ئالغۇ: 3</el-tag>
      <el-tag style="margin: 5px;" type="warning">ئالتۇن ئۈزۈك: 4</el-tag>
      <el-tag style="margin: 5px;" type="danger">ۋەلسىپىت: 5</el-tag>
      <el-tag style="margin: 5px;" type="primary">تىلىفۇن: 6</el-tag>
      <el-tag style="margin: 5px;" type="success">چاچ سۇپۇن: 7</el-tag>
      <el-tag style="margin: 5px;" type="primary">توك سىكىلىت: 2</el-tag>
    </div>

    <!-- 参与人数编辑 -->
    <div
      style="display: flex; justify-content: space-between; align-items: center; padding: 0 15px; margin-bottom: 10px;">
      <div style="display: flex; justify-content: end;margin: 10px; font-size: 12px;">
        ئەمەلىي: {{ totalCount }} ئادەم
        تىزىملاتتى
      </div>

      <div><el-button class="Ukij" type="success" @click="clickTotalUser">{{ total_user }} قاتناشقان ئادەم</el-button>
      </div>
    </div>

    <!-- 添加参与 -->
    <div style="display: flex; justify-content: end; margin: 15px;">
      <el-button class="Ukij" type="primary" @click="dialogEdit = true">ئابۇنىت قوشۇش</el-button>
    </div>

    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="phone_num" label="تىلفۇن نۇمۇر" align="center" width="120" />
      <el-table-column prop="jiang_ping" label="مۇكاپات" align="center" width="70" />
      <el-table-column prop="balance" label="پۇرسەت" align="center" width="90" />
      <el-table-column fixed="right" label="تەھرىرلەش" align="center" width="110">
        <template #default="scope">
          <el-button class="Ukij" type="primary" @click="edit(scope.row)" size="small">ئۆزگەرتىش</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 编辑 -->
    <el-dialog v-model="dialogEdit" title="تەھرىرلەش" width="300">

      <div>
        <el-input class="direction: rtl" v-model="phone_num" style="width: 240px" />

        <div style="direction: rtl;width: 250px; display: flex; margin-top: 15px">مۇكاپات نۇمۇرى:</div>
        <el-input class="direction: rtl" v-model="jiang_ping" style="width: 240px" />

        <div style="direction: rtl;width: 250px; display: flex; margin-top: 15px"> پۇرسەت:</div>
        <el-input class="direction: rtl" v-model="balance" style="width: 240px; margin-top: 15px" />
      </div>

      <template #footer>
        <div class="dialog-footer">
          <el-button class='Ukij' @click="dialogEdit = false">چىكىنىش</el-button>
          <el-button class='Ukij' type="primary" @click="confirm">
            جەزىملەش
          </el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 参与用户编辑 -->
    <el-dialog v-model="dialogTotalUser" title="قاتناشقۇچى تەھرىرى" width="300">

      <div>
        <div style="direction: rtl;width: 250px; display: flex; margin-top: 15px">جەمئىي قاتناشقۇچى:</div>
        <el-input class="direction: rtl" v-model="total_user" style="width: 240px" />
      </div>

      <template #footer>
        <div class="dialog-footer">
          <el-button class='Ukij' @click="dialogTotalUser = false">چىكىنىش</el-button>
          <el-button class='Ukij' type="primary" @click="confirm_dialogTotalUser">
            جەزىملەش
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { changeUserInfo, getUser, changeCanyu } from '../../request/api.js';
export default {
  name: 'Manage',
  data() {
    return {
      msg: '后台',
      jiang_ping: 1,
      balance: 0,
      phone_num: '',
      dialogEdit: false,
      dialogTotalUser: false,
      tableData: [],
      totalCount: 0,
      total_user: 0,
    }
  },
  created() {
    this.getPost();
  },
  methods: {
    getPost() {
      getUser().then(res => {
        this.tableData = res.data.data;
        this.totalCount = res.data.totalCount;
        this.total_user = res.data.total_user[0].canyu_ren;
      })
    },

    edit(e) {
      this.dialogEdit=true;
      this.jiang_ping = e.jiang_ping;
      this.phone_num = e.phone_num;
      this.balance = e.balance;
    },

    // 打开参与用户
    clickTotalUser() {
      this.dialogTotalUser = true;
    },

    // 确认
    confirm() {
      let jiang_ping = parseInt(this.jiang_ping, 10);
      let phone_num = this.phone_num;
      let balance = this.balance;
      let data = { phone_num, jiang_ping, balance };
      changeUserInfo(data).then(res => {
        if(res.status == 200) this.dialogEdit=false;
        this.getPost();
      })
    },

    confirm_dialogTotalUser() {
      this.dialogTotalUser = false;
      let canyu_ren = this.total_user;
      let data = {canyu_ren};
      changeCanyu(data).then(res => {
        this.getPost();
      })
    },
  }
}
</script>

<style>

</style>
