<template>
  <h3 class="Ukij rtl" style="padding: 100px 20px 0 20px;">{{ msg }}</h3>

  <div style="padding: 0 20px 0 20px; margin-top: 50px;">
    <img width="100%" src="../assets/brand.jpg" alt="">
  </div>
</template>
<script>
import { validateCode } from '../../request/api.js';

  export default {
    data() {
      return {
        msg: 'بىر ئاز تەخىر قىلىڭ ...',
        QRCode: '',
      }
    },
    
    created() {
      this.getQueryParams();
    },
    
    methods: {
      // 获取地址参数
      getQueryParams () {
        this.QRCode = this.$route.params.code;
        sessionStorage.setItem('QRCode', this.QRCode);
        
        validateCode(this.QRCode).then(res => {
          if(res.status == 200) {
            this.$router.push({ path: '/' });
          } else {
            // this.$router.push({ path: '/' });
          }
        }).catch(err => {
          // this.$router.push({ path: '/' });
          this.msg = err.data.msg;
        })
      },
    },
  }
</script>